import DemosLayout from '../DemosLayout/DemosLayout'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import '../../scss/common/demo.scss'
import '../ContentSidebar/style.scss'

interface IMarkdownPage {
  title: string
  tableOfContents: string
  html: string
  addClass?: string
}

const MarkdownPage = ({
  title,
  tableOfContents,
  html,
  addClass = '',
}: IMarkdownPage) => {
  const mainSection = (
    <section className={addClass}>
      <div className="Section__container">
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </section>
  )

  const sidebarSection = () => {
    if (tableOfContents) {
      return (
        <div className="contentSidebar" data-testid="content-sidebar">
          <h3>Contents</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: tableOfContents,
            }}
          />
        </div>
      )
    }
  }

  return (
    <>
      <Breadcrumbs pageTitle={title} />
      <DemosLayout
        mainSection={mainSection}
        sidebarSection={sidebarSection()}
      />
    </>
  )
}

export default MarkdownPage
